<template>
  <div id="app-base-db" class="app-base-db">
    <div v-if="!this.$ipc" class="mtp200"></div>
    <div class="login-plant">
      <div class="login-header">
        <div class="login-logo"></div>
        <div class="action" v-if="this.$ipc">
          <div class="min" @click="min(1)"></div>
          <div class="close" @click="min(2)"></div>
        </div>
      </div>

      <div class="login-header-image">
        <div class="imgdiv"></div>
      </div>

      <div class="header-body">
        <div><input class="login-input" v-model="username" placeholder="请输入账号" /></div>
        <div><input class="login-input mtop16" v-model="password" type="password" placeholder="请输入密码" /></div>
        <div class="edit-button">
          <div style="display: flex;"><input type="checkbox" v-model="autoLogin" class="login-checkbox" />
            <div>自动登录</div>
          </div>
          <div style="display: flex;"><input type="checkbox" v-model="setPassWord" class="login-checkbox" />
            <div>记住密码</div>
          </div>
          <div @click="findPassword">找回密码</div>
        </div>
        <button class="login-button" @click="loginSumbit">登录</button>
        <div class="dec-plant">
          <div class="dec-p"><input type="checkbox" v-model="qrdl" class="login-checkbox-dec" />
            <div class="dec-text">我已阅读并同意<span class="doc-text" @click="userXY(1)">《用户协议》</span>和<span class="doc-text" @click="userXY(2)">《隐私政策》</span></div>
          </div>
        </div>
      </div>
      <div v-show="bgs" style="width: 100%; height:100%; background-color: rgba(0, 0, 0, 0.7);position: absolute; top: 0px; color:#fff; border-radius: 10px; line-height: 28;">正加载数据...</div>
    </div>
    <a-modal title="" style="bottom: 20px" v-model="emoVisible" :closable="false"  :footer="null" width="960px" height="640px">
      <div class="dlaheader">
        <div class="dlatitle">{{dlaname}}</div>
        <div class="black-close" @click="mindla()"></div>
      </div>
      <div class="dlabody" v-html="dlacontent"></div>
    </a-modal>
   
  </div>
</template>
<script>
import { ipcApiRoute , requestHttpPost , requestHttpGet} from '@/api/main'
import storage from 'store2'
import { EaseChatClient } from '@/IM/initwebsdk'
import { message } from 'ant-design-vue';
import { ConversationUtils } from '@/utils/ConversationUtils'
export default {
  data() {
    return {
      username:"",
      password:"",
      autoLogin:false,
      setPassWord:false,
      bgs:false,
      qrdl:true,
      dlaname:"用户协议",
      dlacontent:"",
      dlatitle:"",
      emoVisible:false
    };
  },
  mounted() {
    this.setPassWord = storage.get('setPassWord') == null ? false : storage.get('setPassWord')
    if(storage.get('setPassWord')){
      this.username = storage.get('userloginname')
      this.password = storage.get('userloginpwd')
    }

    this.autoLogin = storage.get('autoLogin') == null ? false : storage.get('autoLogin')
    if(storage.get('autoLogin')){
      if(storage.get('ImAcount')){
        if (this.$ipc) {
          this.$ipc.invoke(ipcApiRoute.editWinSize, 2).then(result => {
              console.log(result)
          })
            this.$router.push('/')
          } else {
            this.$router.push('/')
          }
      }
    }else{
      storage.remove("ImAcount")
      storage.remove("ImPassword")
      storage.remove("UserName")
      storage.remove("UserHeader")
      storage.remove("IMUser")
      storage.remove("IMToken")
      storage.remove("ks")
      storage.remove("us")
      storage.remove("Shop_Type")
      storage.remove("newFiledsList")
      storage.remove("newGroupsList")
    }
  },
  methods: {
    mindla(){
      this.emoVisible = false
    },
    async userXY(type){
      if(type == 1){
        //用户协议
        this.dlaname = "用户协议"
        this.dlatitle = "userxy"
        this.dlacontent = "特别提醒用户认真阅读本《用户服务协议》"
        this.contenturl = window.location.protocol+"//"+window.location.host+"/#/userxy"
      }else{
        //隐私政策
        this.dlaname = "隐私政策"
        this.dlatitle = "yszc"
        this.dlacontent = "【特别提示】 本政策仅适用于黑龙江省缤丽电子商务有限公司及其关联公司（以下或称“我们”或“缤丽公司”）提供的产品和服务及其延伸的功能（以下简称“缤丽优家”）"
        this.contenturl = window.location.protocol+"//"+window.location.host+"/#/yszc"
      }
      if (this.$ipc) {
        this.$ipc.invoke(ipcApiRoute.newWinSize, {name:this.dlatitle,content:this.contenturl,title:this.dlaname}).then(result => {
            console.log(result)
        })
      } else {
        //弹窗
        let data = await requestHttpGet('https://ucenter.binliyoujia.com/index.php?ctl=Login&met=protocol&typ=json', {})
        if(type == 1 ){
          this.dlacontent = data.data.reg_protocol
        }else{
          this.dlacontent = data.data.privacy_protocol
        }
        console.log(data)
        this.emoVisible = true
      }
    },
    min(type) {
      this.$ipc.invoke(ipcApiRoute.action, type).then(result => {
        console.log(result)
      })
    },
    async loginSumbit(){
      if(!this.qrdl){
        message.info('请先同意用户协议和隐私政策');
        return
      }
      if(!storage.get('ImAcount')){
        //登录
        let res = await requestHttpPost('https://ucenter.binliyoujia.com//index.php?ctl=Login&met=login&typ=json', {
          user_account:this.username,
          user_password:this.password,
          token:"",
          type:3
        })
        if(res.status == 200){
          //获取Im登录账户 
          let ImAccount = await requestHttpGet('https://www.binliyoujia.com//index.php?ctl=Index&met=checkApp&typ=json', {
            user_name:res.data.user_name,
            user_password:this.password,
            user_moblie: res.data.mobile
          })
          //缓存IM账户信息
          

          storage.set('ImAcount',ImAccount.data.user_im_username)
          storage.set('ImPassword',ImAccount.data.user_im_password)

          //获取用户信息
          let userInfo = await requestHttpGet('https://www.binliyoujia.com//index.php?ctl=Index&met=wxappcheckApp&typ=json', {
            ks:ImAccount.data.k,
            us:ImAccount.data.user_id
          })
          //缓存用户信息
          storage.set('ks',ImAccount.data.k)
          storage.set('us',ImAccount.data.user_id)
          // storage.set('UserName',userInfo.data.data.data.user_name)
          if(userInfo.data.data.data.nickname == null || userInfo.data.data.data.nickname == undefined || userInfo.data.data.data.nickname == '') {
            if(ImAccount.data.shop_name){
              storage.set('UserName',ImAccount.data.shop_name)
            }else{
              storage.set('UserName',ImAccount.data.user_name)
            }
					} else {
						storage.set('UserName',userInfo.data.data.data.nickname)
					}
          storage.set('UserHeader',userInfo.data.data.data.user_avatar)
          storage.set('Shop_Type',userInfo.data.data.data.shop_type)
          //缓存用户设置
          let obj = {
            newMesagechecked:userInfo.data.data.data.sound_notification == 1 ? true:false,
            noteMesagechecked:userInfo.data.data.data.message_details == 1 ? true:false,
            dialogMesagechecked:userInfo.data.data.data.message_popup == 1 ? true:false,
            sendKeychecked:false,
          }
          storage.set(ImAccount.data.user_im_username+"seting",JSON.stringify(obj))

          
          //缓存用户设置
          storage.set('autoLogin',this.autoLogin)
          storage.set('setPassWord',this.setPassWord)
          if(this.setPassWord){
            storage.set('userloginname',this.username)
            storage.set('userloginpwd',this.password)
          }
          storage.set('newFiledsList',JSON.stringify([]))
          storage.set('newGroupsList',JSON.stringify([]))
          let stat = storage.getAll()
          Object.keys(stat).forEach(m => {
            if(m.indexOf('header-avatarur')>=0){
              storage.remove(m)
            }
            if(m.indexOf('header-name')>=0){
              storage.remove(m)
            }
          });
          this.bgs = true
          //登录IM
          await EaseChatClient.open({user: storage.get('ImAcount'),pwd: storage.get('ImPassword')})
          console.log("开始缓存信息")
          await ConversationUtils.getMessageSaveLocalList(this)
          await ConversationUtils.getGroupSaveLocalList(this)
          console.log("信息缓存完成")
          if(EaseChatClient.isOpened()){
            EaseChatClient.close();
          }
          // storage.set("preImages",JSON.stringify([]))
          this.bgs = false
          if (this.$ipc) {
            this.$ipc.invoke(ipcApiRoute.editWinSize, 2).then(result => {
              console.log(result)
            })
            this.$router.push('/')
          } else {
            this.$router.push('/') 
          }
        }else{
          this.$message.error(res.msg);
        }
      }else{
        if (this.$ipc) {
            this.$ipc.invoke(ipcApiRoute.editWinSize, 2).then(result => {
              console.log(result)
            })
            this.$router.push('/')
          } else {
            this.$router.push('/')
          }
      }
    },
    findPassword(){
      if (this.$ipc) {
        this.$ipc.invoke(ipcApiRoute.editWinSize, 3).then(result => {
          console.log(result)
        })
        this.$router.push('/findpwd')
      } else {
        this.$router.push('/findpwd')
      }
    },
    async dbOperation (ac,search_age,info) {
      const params = {
        action: ac,
        info: info,
        search_age: search_age,
        update_name: search_age,
        update_age: search_age,
        delete_name: search_age,
      }
      let res =  await this.$ipc.invoke(ipcApiRoute.dbOperation, params)
      return res
    }
  }
};
</script>
<style lang="less" scoped>
.doc-text{
  color: #ED2616;
}
.dec-p{
  display: flex;
  width: 260px;
  margin: auto;
  margin-top: 16px;
  cursor: pointer;
}
.dec-plant{
  width: 100%;
}
.dec-text{
  color: #999;
  font-size: 12px;
}
.login-button {
  width: 260px;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(90deg, #FE5D42 0%, #ED2616 100%);
  border: none;
  color: #fff;
  margin-top: 21px;
  cursor: pointer;
}
.login-checkbox-dec {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-top: 2px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px #D8D8D8 solid;
  cursor: pointer;
  position: relative;
}

.login-checkbox-dec::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-top: 1px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px #D8D8D8 solid;
  cursor: pointer;
}


.login-checkbox-dec:checked::before {
  content: "\2714";
  display: block;
  text-align: center;
  background-color: #ED2616;
  color: #fff;
  position: absolute;
  top: -1px;
  width: 12px;
  height: 12px;
  border: 1px solid #ED2616;
  font-size: 10px;
  border-radius: 2px;
  line-height: 12px;
}
.login-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-top: 1px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px #D8D8D8 solid;
  cursor: pointer;
  position: relative;

}

.login-checkbox::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  background: #fff;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-top: 1px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px #D8D8D8 solid;
  cursor: pointer;
}


.login-checkbox:checked::before {
  content: "\2714";
  display: block;
  text-align: center;
  background-color: #ED2616;
  color: #fff;
  position: absolute;
  top: -1px;
  width: 16px;
  height: 16px;
  border: 1px solid #ED2616;
  font-size: 12px;
  border-radius: 2px;
  line-height: 12px;
}

.edit-button {
  display: flex;
  justify-content: space-between;
  color: #999;
  width: 260px;
  margin: auto;
  margin-top: 6px;
  font-family: PingFang SC;
  font-size: 12px;
  cursor: pointer;
}

.login-input {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  width: 260px;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  color: #999;
}

.login-input:focus {
  outline: none;
  border: 1px solid #D8D8D8;
}

.login-input::placeholder {
  color: #D8D8D8;
}

.mtop16 {
  margin-top: 16px;
}

.header-body {
  margin-top: 45px;
}

.app-base-db {
  border-radius: 10px;
}

.login-plant {
  width: 400px;
  height: 355px;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  margin: auto;
}

.login-header {
  width: 100%;
  height: 106px;
  border-radius: 10px 10px 0px 0px;
  background: linear-gradient(105deg, #FE5D42 0%, #EB5958 51%, #FCAC8C 100%, #FF6F55 100%);
  display: flex;
  position: relative;
}

.mtp200 {
  margin-top: 200px;
}

.login-header-image {
  position: absolute;
  top: 75px;
  width: 100%;
  height: 60px;

}

.login-header-image .imgdiv {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid rgba(236, 47, 34, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(99, 23, 23, 0.3);
  background: #fff;
  background-image: url('~@/assets/headerd.png');
  background-size: 100%;
  margin: auto;
}

.action {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
}

.min {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  background-image: url('~@/assets/minus.png');
  background-size: 100%;
}

.close {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  background-image: url('~@/assets/close.png');
  background-size: 100%;
}
.black-close{
  width: 20px;
  height: 20px;
  margin-right: 16px;
  background-image: url('~@/assets/main-close.png');
  background-size: 100%;
  margin-top: 3px;
}

.login-logo {
  background-image: url('~@/assets/logo.png');
  background-size: 100%;
  position: absolute;
  top: 16px;
  left: 16px;
  width: 70px;
  height: 20px;
  -webkit-app-region: drag;
  -webkit-user-select: none;
}
/deep/ .ant-message-info .anticon, .ant-message-loading .anticon{
  color: #ED2616 !important;
}
.dlaheader{
  display: flex;
  justify-content: space-between;
}
.dlatitle{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #222222;
  font-weight: 400;
}
/deep/ .ant-modal-body{
  padding: 16px;
}
.dlabody{
  margin-top: 16px;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.dlabody::-webkit-scrollbar{
  background-color: #fff;
  width: 3px;
}
.dlabody::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.1);
  background: rgba(0,0,0,0.1);
}
</style>
